import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@refera/ui-web'
import { FilterSearch as FilterIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Grid } from '@material-ui/core'

import { getVerificationToken, GET_USER } from '_modules/authentication/actions'
import {
  getGenericParameters,
  getServiceOrders,
  getStepStatusFilter,
  setDefaultServiceOrderFilter,
  updateServiceOrderFilter,
} from '_modules/service-orders/actions'
import {
  getServiceOrderFilterSelector,
  getServiceOrderIsFilterDirtySelector,
  getServiceOrderLoadingSelector,
  getStepStatusFilterLoadingSelector,
  getStepStatusFilterSelector,
} from '_modules/service-orders/selectors'
import { userSelector } from '_modules/authentication/selectors'
import { AGENCY_TYPES_OPTIONS } from '_utils/constants'
import { GENERIC_PARAMETERS } from '_utils/constants/service-order'
import useRolePermission from '_hooks/use-role-permission'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-toggle'
import {
  ServiceOrderMainTable,
  ServiceOrderMainViewFilter,
  FILTER_OPTIONS,
} from '_components/service-order'
import HeaderTitle from '_components/header-title'
import Tips from '_/components/tips'

import useStyles from './styles'
import { useLocation } from '@reach/router'

const TradesmanView = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const stepStatusFilter = useSelector(getStepStatusFilterSelector)
  const isStepStatusFilterLoading = useSelector(getStepStatusFilterLoadingSelector)
  const user = useSelector(userSelector)
  const filters = useSelector(getServiceOrderFilterSelector)
  const isFilterDirty = useSelector(getServiceOrderIsFilterDirtySelector)
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const { isAdmin } = useRolePermission()
  const { isTradesman } = useRolePermission()

  const [page, setPage] = useState(filters?.page || 0)
  const [pageSize, setPageSize] = useState(filters?.pageSize || 10)
  const [orderBy, setOrderBy] = useState(filters?.orderBy || '')
  const [isFilterOpen, handleFilterOpen] = useToggle()

  const [isGetUserLoading] = useFetchCall(GET_USER)
  const isGetServiceOrdersLoading = useSelector(getServiceOrderLoadingSelector)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isDelayed = queryParams.get('delayed') === 'true'

  const isLoading = useMemo(
    () => isGetServiceOrdersLoading || isGetUserLoading,
    [isGetServiceOrdersLoading, isGetUserLoading]
  )

  const defaultValues = useMemo(
    () => ({
      [FILTER_OPTIONS.REQUESTER_DATA]: filters?.[FILTER_OPTIONS.REQUESTER_DATA] || '',
      [FILTER_OPTIONS.FULL_ADDRESS]: filters?.[FILTER_OPTIONS.FULL_ADDRESS] || '',
      [FILTER_OPTIONS.SERVICE_ORDER_ID]: filters?.[FILTER_OPTIONS.SERVICE_ORDER_ID] || '',
      [FILTER_OPTIONS.STEP_STATUS]: filters?.[FILTER_OPTIONS.STEP_STATUS] || '',
      [FILTER_OPTIONS.SITUATION]: filters?.[FILTER_OPTIONS.SITUATION] || '',
    }),
    [filters]
  )

  useEffect(() => {
    dispatch(getVerificationToken())
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
      })
    )
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.DELAYED,
      })
    )
  }, [])

  useEffect(() => {
    if (!stepStatusFilter && !isStepStatusFilterLoading) {
      dispatch(getStepStatusFilter())
    }
  }, [dispatch, stepStatusFilter, isStepStatusFilterLoading])

  useEffect(() => {
    if (user?.getRoles && firstPageLoad) {
      if (!isFilterDirty) {
        dispatch(
          setDefaultServiceOrderFilter({
            isAdmin,
            isTradesman,
            userId: user?.id,
            agency: AGENCY_TYPES_OPTIONS[0]?.value,
          })
        )
      }

      setFirstPageLoad(false)
    }
  }, [firstPageLoad, isTradesman, user])

  useEffect(() => {
    if (!isGetServiceOrdersLoading && !firstPageLoad) {
      dispatch(
        getServiceOrders({
          ...filters,
          tradesman: user.getServiceProviderId,
          page: page + 1,
          pageSize,
          order: orderBy,
        })
      )
      dispatch(updateServiceOrderFilter({ ...filters, page, pageSize, order: orderBy }))
    }
  }, [firstPageLoad, orderBy, page, pageSize, user, isDelayed])

  const handleOrderBy = useCallback(orderObj => {
    const order = orderObj[0]

    const { field, sort } = order

    setOrderBy(`${sort === 'desc' ? '-' : ''}${field}`)
  }, [])

  return (
    <>
      <ServiceOrderMainViewFilter
        handleFilterOpen={handleFilterOpen}
        open={isFilterOpen}
        defaultValues={defaultValues}
        setPage={setPage}
        isDelayed={isDelayed}
      />

      <HeaderTitle title="Gestão de chamados">
        <Tips component="filters_tradesman">
          <Button
            disabled={isLoading}
            variant="ghost"
            startIcon={<FilterIcon color={Theme.Colors.Primary.Base} />}
            onClick={handleFilterOpen}
          >
            Filtros
          </Button>
        </Tips>
      </HeaderTitle>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <ServiceOrderMainTable
            onPageChange={setPage}
            page={page}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            onSortModelChange={handleOrderBy}
            isTradesman
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TradesmanView
